
.shop-search {
	position:relative;
	background-image: linear-gradient(-180deg,rgba(0,0,0,.3),rgba(0,0,0,.1));
	height: 450px;
	padding-top:50px;
	padding-bottom:50px;
	overflow-x:hidden;
}
@media (max-width: 767.98px) {
.shop-search {
	padding-top:5px;
	padding-bottom:5px;
}
}
.shop-search > img {
	position:absolute;
	top:0px;
	z-index: -1;
	height: 450px;
	max-width: none;
	min-width: 100%;
}
.shop-search h1,
.shop-search h2 {
	font-size:50px;
	font-weight:900;
	color: #ffffff;
	text-shadow: 0 2px 5px rgba(0,0,0,.5)
	/* text-shadow: #FFF 2px 2px, #FFF -2px -2px, #FFF -2px 2px, #FFF 2px -2px; */
}
.shop-search .subtitle {
	display:block;
	text-align: center;
	margin-bottom: 5px;
	font-size: 20px
}
.shop-search .example {
	margin-left:18px;
	margin-top:5px;
}
.suggestions.spinner {
	right: 20px;
    width: 100px;
	padding-left: 2px;
	padding-top:50px;
}
.redirection.spinner {
	width: 100%;
	left: 0px;
	background: white;
	opacity: 50%;
	padding-top:50px;
}
.suggestions.spinner > div {
	width: 14px;
	height: 14px;	
	margin-top:18px;
}
.redirection.spinner > div {
	margin-top:16px;
}
.shop-search .landing-wide-form {
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	position:relative;
	padding:15px;
}
.shop-search .form-control {
	height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}
.shop-suggestion {
	display:flex;
}
.shop-suggestion .logo {
	width:60px;
	height:60px;
	margin-right: 10px;
}
.shop-suggestion .info {
	display:flex;
	flex-direction: column;
	padding:10px;
}
.shop-suggestion .info > span {
	display:block;
	line-height: 20px;
}
.shop-suggestion .info .name {
	font-weight: bold;
}
.shop-search .autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 5px;
}




/* We set a max size for logos */
.vignerons .vigneron .logo {
	max-width:200px;
}

/* We reduce the sections margins */
.vignerons .section.vigneron {
	margin: 30px 0px;
	padding: 30px 0px;
}


